import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorNick as author } from 'data/authors';
import img from 'img/blog/covers/cover-not-all-traffic.png';
import img1 from 'img/blog/not-all-traffic-is-created-equally/analysis.png';
import img2 from 'img/blog/not-all-traffic-is-created-equally/utm.png';

const Content = () => {
  return (
    <Post>
      <p>
        Sources of traffic to a website are the channels through which visitors come to a website.
        As you know, it is the number of visitors that is one of the main indicators of the success
        of the project. The more of them, the higher the likelihood of achieving your conversion
        goal. Of course, not every site can be proud of a steady flow of users. Knowledge of where
        to effectively attract customers, as well as high-quality analysis of traffic sources, will
        help to increase the traffic indicator.{' '}
      </p>
      <h3>Classification</h3>
      <p>
        Before talking directly about the analysis, it is necessary to decide which traffic sources
        are distinguished by experts on different channels.
      </p>
      <p>
        <strong>A search engine</strong> - visitors clicked on the site through natural results for
        a keyword. This type is the most valuable and desirable for many marketers, as it is the
        result of difficult work - search engine promotion of the resource among thousands of
        competitors. How do you get it? Increase the rating of your portal, achieve a high ranking
        by its search robots. It&apos;s not easy, in every sense expensive, but worth it. Good{' '}
        <a
          href="https://seosydneyexperts.com.au/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          quality SEO
        </a>{' '}
        is a must-have for every brand or business.
      </p>
      <p>
        {' '}
        <strong>Link</strong> - a click on links from other resources. For example, from those where
        you left banner ads or comments with a link to your resource, or if you are registered in
        any database, you can go on and on.
      </p>

      <p>
        <strong>The target traffic source</strong> - visits from regular readers who subscribed to
        your blog updates or bookmarked the resource. Of course, such leads will not appear
        immediately, newcomers simply cannot have them.{' '}
      </p>

      <p>
        <strong>Social</strong> - is one of the most relevant, and as you might have guessed, it
        comes from social networks. By posting announcements and other links to your own Facebook or
        any other platform, you{' '}
        <a
          href="https://www.wordstream.com/blog/ws/2014/08/14/increase-traffic-to-my-website"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          attract new visitors
        </a>{' '}
        to the site.{' '}
      </p>

      <p>
        <strong>Advertising</strong> - generates leads by placing advertisements of various kinds.
        This can be a display type of advertising, contextual links, a teaser. Here you can also
        conditionally add leads from affiliate programs, which are very relevant as sources of
        traffic for an online store.
      </p>

      <p>
        Let&apos;s dwell on affiliate programs in more detail, with the help of them you can
        significantly increase the traffic of the web resource. This is the interaction between the
        advertiser and the publisher through specially created networks.
      </p>

      <p>
        Today, the most profitable format for working with affiliate programs is CPA (Cost per
        Action), payment for the action of an attracted user on the advertiser&apos;s
        (customer&apos;s){' '}
        <a href="/blog/why-do-people-hate-your-website/" target="_blank" rel="noopener noreferrer">
          website
        </a>
        . CPA is considered to be the most effective in attracting interested customers when viewed
        in terms of the advertiser&apos;s benefit because it pays the webmaster only for specific
        user actions.
      </p>
      <p>
        There are also free traffic sources for CPA, as the paid option is not available to all
        businessmen. The most common channels are:
      </p>
      <ul>
        <li>social platforms</li>
        <li>sites of the &quot;question-answer&quot; type</li>
        <li>forums / blogs</li>
      </ul>
      <p>
        {' '}
        With social networks, everything is clear. Create a page, promote it. Of course, the topic
        of such a public should correspond to the activities of your brand, so that the promotion of
        offers there looks natural.
      </p>
      <p>
        Working with Q&A sites is similar to forums. The specialist selects a certain suitable
        topic, advertises there unobtrusively, providing links to users. Note that these free
        traffic sources are the oldest.
      </p>

      <p>
        Forums/blogs are more complicated. You need to look for a related topic, create discussions
        there. In the absence of such - to form this topic yourself. Let&apos;s be honest,
        advertising is always masked here.{' '}
      </p>

      <p>
        With a blog, things are the same - create your own magazine, start promoting it, but keep in
        mind that you can place ads more freely on paid blogging platforms, which is very important
        for the CPA method.
      </p>

      <p>
        The analysis of traffic sources will help to understand which channel of receiving visitors
        is lagging behind. As a rule, it is carried out using web analytics systems.
      </p>
      <h3>Analysis</h3>
      <img
        src={img1}
        className="img-fluid justify-content-center mb-4"
        alt="analysis"
        loading="lazy"
        title="Analysis"
      />
      <p>
        For better analysis, there are additional tools, for example, dividing reports into several
        categories. So, the{' '}
        <a
          href="https://whatagraph.com/google-analytics-pdf-report"
          target="_blank"
          rel="noopener noreferrer"
        >
          report on Google Analytics
        </a>{' '}
        channels is divided into several groups:
      </p>
      <ul>
        <li>&quot;Organic&quot; sources of Internet traffic - from natural search results</li>
        <li>Referral traffic sources - visitors who followed links from third-party portals</li>
        <li>Direct traffic sources - leads that come from contextual campaigns</li>
        <li>Display traffic sources - display ad visitors</li>
        <li>Traffic from email</li>
      </ul>
      <p>
        {' '}
        Information is usually presented in a diagram or graph, table. You can see which visitors
        are staying on the resource and where they came from. To do this, you need to select a
        report by sources. Then you can see exactly where the leads came from.{' '}
      </p>

      <p>
        If you are looking for how to determine the source of traffic based on the keywords that
        users use to find your property, then the All Traffic report can help you. However, do not
        be surprised if some will be closed, Google is actively fighting for the rights of users and
        their anonymity.
      </p>

      <p>Using the following report for all transitions, you can:</p>
      <ul>
        <li>
          see a list of all sites from which users went to the resource, as well as see various
          indicators for the actions of these leads
        </li>
        <li>see a list of the most popular pages</li>
      </ul>

      <p>
        Separately, it is worth noting that the Analytics developers have added reports on social
        functions. This group offers the same overview, where you can see the social networks from
        where users visit your site, the same information as a percentage, and the URLs of those
        pages to which users come more often. A very useful function to conduct a qualitative
        analysis of traffic sources.
      </p>

      <p>
        Don’t ignore sources of mobile traffic, as the number of mobile users is growing
        exponentially, and of course, keeping in mind the social functions, this is very important.
        These reports can also be viewed in Google Analytics.
      </p>

      <p>
        The problem is that these statistics do not allow the analysis of every ad, link or banner.
        For example, in the report &quot;Conversions from social networks&quot; you can see the
        <a href="/blog/low-conversion-rate/" target="_blank" rel="noopener noreferrer">
          number of conversions
        </a>{' '}
        from Twitter, Facebook, Pinterest and other well-known sites, but you will not know where
        exactly - from a post in some group, advertising announcements, link in the profile - the
        transition is made.
      </p>

      <p>
        Let&apos;s say you are advertising a site on Facebook by buying advertising posts in groups
        and simultaneously testing several targeted ads. In analytics systems, all of them will be
        marked as transitions from Facebook. Considering that in addition to paid advertising, you
        may also have organic referrals from Facebook, this confuses the cards even more.
      </p>
      <p>How then to deal with all this chaos?</p>
      <h3>UTM tags</h3>
      <img
        src={img2}
        className="img-fluid justify-content-center mb-4"
        alt="utm's"
        loading="lazy"
        title="UTM tags"
      />
      <p>
        UTM tags are the easiest and most effective way to track traffic sources. SMMs and those who
        set up ads in Google are familiar with them. But this tool has a much wider range of uses -
        banner ads, forum posts, links in your own accounts and groups, offline flyers, etc.
      </p>

      <p>
        UTM tags can also be useful for analysing the effectiveness of free traffic channels. For
        example, if you post links in some blog and want to know if they are clicked at all, whether
        the links at the bottom of the article are effective, etc. Or, for example, you have a{' '}
        <a
          href="https://blog.thecrowdfundingformula.com/facebook-group-marketing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook group
        </a>{' '}
        and people can go to the site using the links in the group menu or posts on the wall
        (including those fixed). Statistics will help you determine if they are useful in your case
        and which formats work more efficiently.
      </p>

      <p>
        In general, UTM tags provide a convenient and useful toolkit, and for what purposes it is up
        to you to use it.
      </p>
      <h3>How to start generating UTM tags in qualitative analytic tools</h3>
      <p>
        Be sure to define goals and objectives for analytics. Select the required slicers for data
        analysis. Design your campaigns to meet the above requirements. Consider the UTM markup
        based on this. But to create UTM-tags in analytic tools, it is not enough just to know the
        principles of their filling. Many companies have built omnichannel communication with their
        customers. This means that it is necessary to combine data from dozens of sources. And even
        if now you use only 2-3 sources, after a while there will be more of them - and it will
        become more difficult to keep track of everything.
      </p>
      <p>
        It is even more difficult to control the situation when different agencies/teams are engaged
        in marketing, and they change quite often. Each has its own nuances of work and options,
        which is more correct. To avoid mistakes and confusion in the markup, as well as to secure
        your processes when changing decision-makers or agencies, we suggest regulating the work at
        the initial stages of building UTM markup. For each channel type, create a document in which
        you detail the rules for generating the values ​​of UTM tags. Be sure to check the
        consistency of label values ​​between systems.
      </p>

      <p>
        There are tasks when analytics is built for atypical business tasks. It is impossible to
        give specific recommendations for all such cases since each case must be considered
        separately. For example, in certain circumstances, you can assign the value of the UTM tag
        inappropriately in order to track an important indicator for the business. But we won&apos;t
        advise you to experiment with this on your own, it is better to contact a specialist.
      </p>
      <h3>Conclusion</h3>
      <p>
        So, in this article, we have covered the main sources of traffic and their importance. Now
        you know that UTM tags have two advantages at once. First, you get great granularity and
        results by channel, source, and campaign in one place. Secondly, you get all the information
        about how and where the traffic came to you. This is what every marketer should know.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: "How To Measure The Importance of Traffic Sources Using UTM's",
  metaTitle: 'Measuring The Importance of Traffic Sources',
  url: '/blog/not-all-traffic-is-created-equally/',
  description: `The number of visitors is one of the main indicators of the success of the project - more of them, the higher the likelihood of achieving your conversion goal.`,
  author,
  img,
  imgSocial: img,
  date: '2021-03-15',
  category: '',
  group: 'Analytics',
  timeToRead: 8,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
    metaTitle={frontmatter.metaTitle}
  />
);
